import React, { useEffect, useState } from "react";
import axios from "axios"; // Assuming you're using Axios for HTTP requests
import "./App.css";
import { InfinitySpin } from "react-loader-spinner";
import ReactPlayer from "react-player";
function UserReport() {
  const [data, setData] = useState([]);
  const [username, setUsername] = useState();
  const [isIELTSscoring, setisIELTSscoring] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const [OnlymediaArray, setOnlyMediaArray] = useState([]);
  const [textArray, setTextArray] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const senderId = queryParams.get("senderId");
        const starttime = queryParams.get("starttime");

        const response = await axios.post(
          //"https://hnylearning.com/awarenessHubBackend/getData/getDetailedUserReportWithTextResponse/",
          "https://hnylearning.com/awarenessHubBackend/getData/getDetailedUserReportWithTextResponse2/",
          {
            senderId: senderId,
          },
          {
            headers: {
              authtoken: "Awareness-Hub@7895",
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response", response);

        setData(response.data.data);
        console.log("response.data.data", response.data.data);
        const arr = response.data.data;
        const resapp = arr[0].response;
        console.log("resapp", resapp);
        const username = resapp.userName;
        console.log("username", username);
        setUsername(username);

        const isIELTSscoring = resapp.isIELTSscoring;
        console.log("isIELTSscoring", isIELTSscoring);
        setisIELTSscoring(isIELTSscoring);
        const mediaArray = resapp.multimediaStagesObj;
        console.log("mediaArray", mediaArray);
        setMediaArray(mediaArray);
        const OnlymediaArray = resapp.onlyMediaStagesObj;
        console.log("OnlymediaArray", OnlymediaArray);
        setOnlyMediaArray(OnlymediaArray);
        const textArray = resapp.textStagesObj;
        console.log("textArray", textArray);
        setTextArray(textArray);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <>
      {loader ? (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
          <InfinitySpin
            visible={true}
            width="150"
            color="#02ccfe"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : null}

      <div className="main-container">
        {loader ? null : (
          <div style={{ paddingTop: "5px" }} className="sub-container">
            <div className="row text-center">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                {" "}
                <img width={150} className="logo" src="./hnylogo.png" />
              </div>
              <div className="col-md-4"></div>
            </div>
            <hr />
            <div style={{ marginTop: 30 }} className="row">
              <p className="main-heading">
                Here’s a quick report of <b>{username}</b>
              </p>
            </div>

            <div style={{ marginTop: 10, padding: 0 }} class="container">
              <ul
                style={{ border: 0 }}
                class="nav nav-tabs"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="video-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#video"
                    type="button"
                    role="tab"
                    aria-controls="video"
                    aria-selected="false"
                  >
                    Video Inputs
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="text-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#text"
                    type="button"
                    role="tab"
                    aria-controls="text"
                    aria-selected="true"
                  >
                    Text Inputs
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade"
                  id="text"
                  role="tabpanel"
                  aria-labelledby="text-tab"
                >
                  <div style={{ marginTop: 25 }} className="mt-10">
                    {textArray.map((section, sectionIndex) => {
                      const hasResults = section.data.some(
                        (item) => item.Result !== undefined
                      );
                      return (
                        <div key={sectionIndex}>
                          <h2
                            style={{
                              fontSize: 23,
                              fontWeight: 600,
                              marginTop: 50,
                            }}
                          >
                            {section.sectionName}
                          </h2>

                          {hasResults &&
                            section.sectionScore !== 0 &&
                            section.sectionScore !== "" && (
                              <>
                                <p className="fontsize">
                                  Section Score: <b>{section.sectionScore}</b>{" "}
                                  out of <b>{section.numberOfQuizQuestions}</b>{" "}
                                  ({section.sectionScoring})
                                </p>
                              </>
                            )}
                          {section.data.map((item, itemIndex) => (
                            <div key={itemIndex}>
                              <p className="question">
                                <b style={{ width: 150 }}>
                                  {`Question ${itemIndex + 1}`}:{" "}
                                </b>

                                {`${item.BotText}`}
                              </p>
                              <p className="answertext">
                                <b>Answer: </b>
                                {item.userText}&nbsp;
                                <span>
                                  {item.Result ? (
                                    <>
                                      {item.Result == "RIGHT" ? (
                                        <>
                                          (
                                          <span
                                            style={{
                                              backgroundColor: "#ffff00",
                                            }}
                                          >
                                            Right
                                          </span>
                                          )
                                        </>
                                      ) : (
                                        <>
                                          (
                                          <span
                                            style={{
                                              backgroundColor: "#c9daf8",
                                            }}
                                          >
                                            Wrong
                                          </span>
                                          )
                                        </>
                                      )}
                                    </>
                                  ) : null}
                                </span>
                              </p>
                              {/* {item.Score !== undefined && (
                              <p>
                                <strong>Score:</strong> {item.Score}
                              </p>
                            )}
                            <p>
                              <strong>Time:</strong> {item.time}
                            </p> */}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  class="tab-pane fade show active"
                  id="video"
                  role="tabpanel"
                  aria-labelledby="video-tab"
                >
                  <div style={{ marginTop: 25 }} className="mt-10">
                    {mediaArray.map((item, index) => (
                      <div key={index} className="faq-item">
                        {/* Check if index is greater than 0 to start from index 1 */}
                        {item.type === "videoInput" && (
                          <>
                            <p style={{ marginTop: 30 }} className="question">
                              <b>{`Question ${index + 1}`}</b>
                              {`: ${item.BotText}`}
                            </p>
                            <br />

                            {isIELTSscoring == true ? (
                              // <div className="IELTS">
                              //   <div className="row">
                              //     <div className="col-md-3 scoresize">
                              //       Overall score:{" "}
                              //       <b>{item.Overall_score}/9.0</b>
                              //     </div>
                              //     <div className="col-md-3 scoresize">
                              //       Revelance score: <b>{item.Relevance}%</b>
                              //     </div>
                              //   </div>
                              //   <hr />
                              //   <div
                              //     style={{ marginTop: 5 }}
                              //     className="col-md-7"
                              //   >
                              //     <div className="row">
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Fluency & Coherence:
                              //         <b>{item.Fluency}/9.0</b>
                              //       </div>
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Lexical resource: <b>{item.Relevance}%</b>
                              //       </div>
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Grammatical range & Accuracy:{" "}
                              //         <b>{item.Grammar}%</b>
                              //       </div>
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Pronunciation:{" "}
                              //         <b>{item.Pronounciation}%</b>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </div>
                              <div className="container row">
                                <div
                                  style={
                                    {
                                      //border: "1px solid rgba(0, 0, 0, 0.8)",
                                      //padding: 20,
                                      //borderRadius: 20,
                                    }
                                  }
                                  className="col-md-9"
                                >
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div
                                        style={{ marginTop: 30 }}
                                        className="text-center"
                                      >
                                        <h5 className="mb-3">Overall</h5>
                                        <div className="display-4 fw-bold text-primary">
                                          {item.Overall_score}/9.0
                                        </div>
                                      </div>
                                      <div className="text-center mb-4">
                                        <small className="text-muted">
                                          Topic relevance score:{" "}
                                          <strong>{item.Relevance}%</strong>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="row">
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-primary">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Fluency & Coherence
                                              </h6>
                                              <p className="display-6 text-success mb-0">
                                                {item.Fluency}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-secondary">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Lexical Resource
                                              </h6>
                                              <p className="display-6 text-primary mb-0">
                                                {item.lexical_resource}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-warning">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Grammatical Range & Accuracy
                                              </h6>
                                              <p className="display-6 text-warning mb-0">
                                                {item.Grammar}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-danger">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Pronunciation
                                              </h6>
                                              <p className="display-6 text-danger mb-0">
                                                {item.Pronounciation}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>{item.transcription}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="NonIELTS">
                                <div className="row">
                                  <div className="col-md-3 scoresize">
                                    Overall score: <b>{item.Overall_score}%</b>
                                  </div>
                                </div>

                                <div
                                  style={{ marginTop: 5 }}
                                  className="col-md-7"
                                >
                                  <div className="row">
                                    <div
                                      style={{ fontSize: 15 }}
                                      className="col-md-3 scoresize"
                                    >
                                      Relevance: <b>{item.Relevance}%</b>
                                    </div>
                                    <div
                                      style={{ fontSize: 15 }}
                                      className="col-md-3 scoresize"
                                    >
                                      Fluency: <b>{item.Fluency}%</b>
                                    </div>
                                    <div
                                      style={{ fontSize: 15 }}
                                      className="col-md-3 scoresize"
                                    >
                                      Grammar: <b>{item.Grammar}%</b>
                                    </div>
                                    <div
                                      style={{ fontSize: 15 }}
                                      className="col-md-3 scoresize"
                                    >
                                      Pronunciation:{" "}
                                      <b>{item.Pronounciation}%</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <br />
                            <div className="mt-10">
                              <div
                                style={{ maxWidth: 480 }}
                                className="player-wrapper"
                              >
                                <ReactPlayer
                                  className="react-player"
                                  controls
                                  url={item.videoUrl}
                                  width="100%"
                                  height="100%"
                                  playsInline
                                />
                                {/* <video
                              playsInline
                              controls
                              width="100%"
                              height="100%"
                            >
                              <source src={item.videoUrl} type="video/mp4" />
                            </video> */}
                              </div>
                            </div>
                          </>
                        )}
                        <>
                          <div className="mt-10">
                            {item.type === "voiceInput" && (
                              <div className="m-10">
                                <p className="question">
                                  <b>{`Question ${index}`}</b>
                                  {` (Audio): ${item.BotText}`}
                                </p>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="row">
                                      <div className="col-md-3 scoresize">
                                        Overall score :{" "}
                                        <b>{item.Overall_score}</b>
                                      </div>
                                      <div className="col-md-3 scoresize">
                                        Fluency : <b>{item.Fluency}%</b>
                                      </div>
                                      <div className="col-md-3 scoresize">
                                        Grammar : <b>{item.Grammar}%</b>
                                      </div>
                                      <div className="col-md-3 scoresize">
                                        Pronunciation :{" "}
                                        <b>{item.Pronounciation}%</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-10">
                                  <audio controls>
                                    <source
                                      src={item.audioUrl}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      </div>
                    ))}

                    <hr />

                    {isIELTSscoring == true &&
                      OnlymediaArray.map((item, index) => (
                        <div key={index} className="faq-item">
                          <p style={{ marginTop: 30 }} className="question">
                            <b>{`Question ${index + 1}`}</b>
                            {`: ${item.BotText}`}
                          </p>
                          <br />

                          <div className="mt-10">
                            <div
                              style={{ maxWidth: 480 }}
                              className="player-wrapper"
                            >
                              <ReactPlayer
                                className="react-player"
                                controls
                                url={item.videoUrl}
                                width="100%"
                                height="100%"
                                playsInline
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="row text-center">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <p>
                  Reach us for support at :{" "}
                  <a href="mailto:support@lsb.com">support@lsb.com</a>
                </p>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserReport;
